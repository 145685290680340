/* Mui Lab */
/* custom-popup-date-picker */
.custom-popup-date-picker > .MuiPaper-root {
  border-radius: 4px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
}

.custom-popup-date-picker > .MuiPaper-root > div > div {
  width: auto;
}

.custom-popup-date-picker .MuiCalendarPicker-root {
  width: 280px;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type > div {
  font-size: 14px;
  color: #29302e;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root {
  border-radius: 4px;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root svg {
  font-size: 18px;
  fill: #29302e;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root.Mui-disabled svg {
  fill: #9e9e9e;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root {
  min-height: 170px;
  max-height: 240px;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .PrivatePickersSlideTransition-root {
  min-height: 210px;
  max-height: 250px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root > div {
  position: relative;
}

/* year */
.custom-popup-date-picker .MuiYearPicker-root .PrivatePickersYear-yearButton {
  font-size: 14px;
  width: auto;
  height: 26px;
  border-radius: 4px;
}

.custom-popup-date-picker .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected {
  background-color: #ff8ab7;
}

/* month */
.custom-popup-date-picker .MuiMonthPicker-root {
  width: 280px;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root {
  height: 26px;
  margin: 8px 0;
  background-color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  color: #29302e;
  position: relative;
  z-index: 1;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root.Mui-selected {
  color: #fff !important;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
  border-radius: 4px;
  z-index: -1;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root:hover::before {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root.Mui-selected::before {
  background-color: #ff8ab7;
}

/* range date picker */
.custom-range-date-picker-wrap > .MuiPaper-root {
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
}

.custom-range-date-picker-wrap > .MuiPaper-root ul {
  padding: 0;
}

.custom-range-date-picker-wrap > .MuiPaper-root ul > div {
  min-width: 280px;
  overflow-x: auto;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:first-of-type * {
  font-size: 14px;
  color: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:first-of-type svg {
  font-size: 18px;
  fill: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayPreview,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-root:first-of-type,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayPreview,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-root:last-of-type,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgba(28, 185, 154, 0.1);
}

/* date */
.custom-popup-date-picker .PrivatePickersFadeTransitionGroup-root .MuiTypography-caption,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiTypography-caption {
  width: 27px;
  height: 27px;
  font-size: 14px;
  margin: 0 4px;
  color: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiTypography-caption {
  width: 31px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today {
  background-color: #fff;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.Mui-disabled {
  color: #9e9e9e;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root {
  width: 27px;
  height: 27px;
  font-size: 14px;
  margin: 0 4px;
  color: #29302e;
  border-radius: 4px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root:hover,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today:hover {
  border: 1px solid #ff8ab7;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root.MuiPickersDay-today {
  border-color: #ff8ab7;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.Mui-selected,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #ff8ab7;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #f5f5f5 !important;
}
